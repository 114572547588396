import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GlobalLogs = ({ setGlobalLogs, globalLogs }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLogs, setFilteredLogs] = useState(globalLogs);

  useEffect(() => {
    const fetchGlobalLogs = async () => {
      try {
        const response = await axios.get('/php/getLogs.php');
        const data = response.data;
        if (Array.isArray(data)) {
          setGlobalLogs(data);
          setFilteredLogs(data);
        } else {
          setGlobalLogs([]);
          setFilteredLogs([]);
        }
      } catch (error) {
        setGlobalLogs([]);
        setFilteredLogs([]);
      }
    };

    fetchGlobalLogs();
  }, [setGlobalLogs]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    const filtered = globalLogs.filter(log =>
      log.question.toLowerCase().includes(event.target.value.toLowerCase()) ||
      log.answer.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredLogs(filtered);
  };

  return (
    <div>
      <h1>Global Q&A History</h1>
      <div>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Buscar en los logs..."
        />
      </div>
      <ul>
        {filteredLogs.map((log, index) => (
          <li key={index}>
            <p><strong>Fecha:</strong> {new Date(log.timestamp).toLocaleString()}</p>
            <p><strong>Pregunta:</strong> {log.question}</p>
            <p><strong>Respuesta:</strong> {log.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GlobalLogs;
