import React, { useState } from 'react';
import axios from 'axios';

const LoginForm = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/php/login.php', { username, password }, { withCredentials: true });
      if (response.data.message === "Login successful") {
        setIsAuthenticated(true);
        setError('');
      } else {
        setError('Invalid credentials');
        setIsAuthenticated(false);
      }
    } catch (error) {
      setError('Invalid credentials');
      setIsAuthenticated(false);
    }
  };

  return (
    <div className="login-form">
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <label>Username:</label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Login</button>
      </form>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default LoginForm;
