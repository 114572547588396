import React, { useState, useEffect } from 'react';
import './App.css';
import Agnes from './components/Agnes';
import GlobalLogs from './components/GlobalLogs';
import FileManager from './components/FileManager';
import LoginForm from './components/LoginForm';
import axios from 'axios';

function App() {
  const [view, setView] = useState('agnes');
  const [userLogs, setUserLogs] = useState([]);
  const [globalLogs, setGlobalLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get('/php/checkSession.php', { withCredentials: true });
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkSession();
    const savedUserLogs = JSON.parse(localStorage.getItem('userLogs')) || [];
    setUserLogs(savedUserLogs);
  }, []);

  const handleLogout = async () => {
    try {
      await axios.get('/php/logout.php', { withCredentials: true });
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <div className="App">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {!isAuthenticated ? (
        <LoginForm setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <div>
          <header className="App-header">
            <nav>
              <button onClick={() => setView('agnes')}>AGNES</button>
              <button onClick={() => setView('log')}>LOG</button>
              <button onClick={() => setView('files')}>Files</button>
              <button onClick={handleLogout}>Logout</button>
            </nav>
            {view === 'agnes' && (
              <Agnes
                setLoading={setLoading}
                setUserLogs={setUserLogs}
                userLogs={userLogs}
                setGlobalLogs={setGlobalLogs}
              />
            )}
            {view === 'log' && (
              <GlobalLogs
                setGlobalLogs={setGlobalLogs}
                globalLogs={globalLogs}
              />
            )}
            {view === 'files' && <FileManager />}
          </header>
        </div>
      )}
    </div>
  );
}

export default App;
