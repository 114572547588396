import React, { useState, useEffect } from 'react';
import apiInstance from '../utils/api';

const FileManager = () => {
  const [files, setFiles] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);

  const fetchFiles = async () => {
    try {
      const response = await apiInstance.get('/list');
      setFiles(response.data.files);
    } catch (error) {
      console.error('Error al obtener la lista de archivos:', error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!fileToUpload) return;

    const formData = new FormData();
    formData.append('file', fileToUpload);

    try {
      await apiInstance.post('/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      fetchFiles();
      setFileToUpload(null);
    } catch (error) {
      console.error('Error al subir el archivo:', error);
    }
  };

  const handleFileDelete = async (filename) => {
    try {
      await apiInstance.delete('/delete', { data: { filename } });
      fetchFiles();
    } catch (error) {
      console.error('Error al borrar el archivo:', error);
    }
  };

  return (
    <div>
      <h1>Gestión de Archivos</h1>
      <form onSubmit={handleFileUpload}>
        <input type="file" onChange={(e) => setFileToUpload(e.target.files[0])} />
        <button type="submit">Subir Archivo</button>
      </form>
      <h2>Archivos Subidos</h2>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            {file}
            <button onClick={() => handleFileDelete(file)}>Borrar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileManager;
