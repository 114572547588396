import React, { useState } from 'react';
import axios from 'axios';

const Agnes = ({ setLoading, setUserLogs, userLogs, setGlobalLogs }) => {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!question.trim()) {
      setError('La pregunta no puede estar vacía.');
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post('https://apiagnes.enotoken.io/process', { question });
      const isoTimestamp = new Date().toISOString();
      const formattedTimestamp = isoTimestamp.slice(0, 19).replace('T', ' ');
      const newLog = { question, answer: res.data.respuesta, timestamp: formattedTimestamp };
      const updatedUserLogs = [newLog, ...userLogs];
      setUserLogs(updatedUserLogs);
      localStorage.setItem('userLogs', JSON.stringify(updatedUserLogs));
      await axios.post('/php/addLog.php', newLog);
      setGlobalLogs(prevLogs => [newLog, ...prevLogs]);
      setResponse(res.data.respuesta);
      setQuestion('');
    } catch (error) {
      setError('Hubo un error al procesar la solicitud. Detalles: ' + error.message);
      setResponse('');
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>AGNES MVP 1.0</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Pregunta:
            <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} />
          </label>
        </div>
        <button type="submit">Enviar</button>
      </form>
      {error && <div style={{ color: 'red' }}><h2>Error:</h2><p>{error}</p></div>}
      {response && <div><h2>Respuesta:</h2><p>{response}</p></div>}
      <h2>Historial del Usuario</h2>
      <ul>
        {userLogs.map((log, index) => (
          <li key={index}>
            <p><strong>Fecha:</strong> {new Date(log.timestamp).toLocaleString()}</p>
            <p><strong>Pregunta:</strong> {log.question}</p>
            <p><strong>Respuesta:</strong> {log.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Agnes;
